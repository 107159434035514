import React from 'react'
import memberof from '../../DataBaseFile/memberOf'
import Slider from 'react-slick'

const MemberOf = () => {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };
    return (
        <div className='custom_container '>
            <p className='text-center text-xl  py-10'>MEMBER OF</p>
            {/* <p className='text-2xl uppercase font-bold text-center text-black py-10'>Member of </p> */}
            <Slider {...settings}>
                {
                    memberof?.map(mem =>
                        <a key={mem.id} href={mem.link} target='_blank' className='h-20  mb-16 rounded-md bg-white shadow-md cursor-pointer custom_design '>
                            <img src={mem.img} className=' p-3 h-full w-full object-contain' />
                        </a>
                    )
                }
            </Slider>
        </div>
    )
}

export default MemberOf