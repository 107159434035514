import React from 'react'
import Image1 from '../../assets/image1.jpg'
const HeroBanner = ({ title, img }) => {
    return (
        <div className='h-56 w-full relative'>
            <img src={Image1} alt="" className='w-full h-full object-cover' />
            <div className='absolute top-0 bg-black opacity-40 z-10 h-full w-full'></div>
            <div className='text-white absolute inline z-20  top-0  h-full w-full'>
                <p className=' text-3xl md:text-4xl uppercase top-[45%] w-full absolute text-center'>
                    {`${title}`}
                </p>
            </div>
        </div>
    )
}

export default HeroBanner