import React from 'react'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import demotransthemagictransport from '../.././assets/demotransthemagicoftransport.jpg'

import Slider from "react-slick";

import LocationItem from '../../components/LocationItem/LocationItem'
import locationData from '../../DataBaseFile/locationData'


export const Contactus = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        cssEase: "linear",
    };
    return (
        <div>
            <HeroBanner title={'Contact Us'} />
            <div className='custom_container  rounded-md'>
                <div className='grid md:grid-cols-2 py-6 grid-cols-1' data-aos="fade-right">
                    {/* <div className='bg-blue-300 p-4' data-aos="fade-right">
                        <p className='text-center uppercase text-2xl font-bold text-white'>office location</p>
                        <div className='flex flex-wrap md:mt-5 justify-between'>
                            {
                                locationData.map(locData =>
                                    <LocationItem
                                        key={locData.id}
                                        title={locData.title}
                                        address={locData.address}
                                        phone={locData.phone}
                                        email={locData.email}
                                    />
                                )
                            }
                        </div>
                    </div> */}
                    <div className='h-full'>
                        <a target='_blank' href="https://www.google.com/maps/place/Demotrans/@23.8629754,90.4005366,17z/data=!4m5!3m4!1s0x3755c59c88a45e61:0x7d21bc7aadf3ba53!8m2!3d23.8629479!4d90.4024246">
                            <img src={demotransthemagictransport} className='object-cover hover:opacity-50'
                                loading='lazy'
                            />
                        </a>
                        <Slider {...settings}>
                            {
                                locationData.map(locData =>
                                    <LocationItem
                                        key={locData.id}
                                        title={locData.title}
                                        address={locData.address}
                                        phone={locData.phone}
                                        email={locData.email}
                                    />
                                )
                            }
                        </Slider>

                    </div>

                    <form data-aos="fade-up-left" style={{ backgroundColor: '#E3E3E3' }} className=' p-4' action="https://formsubmit.co/info@demogroup.net" method="POST">
                        <div className='flex md:gap-8 gap-3 flex-wrap md:flex-nowrap'>
                            <div className='md:w-1/2 w-full'>
                                {/* <label for="firstName" className='text-sm'>Name</label> <br /> */}
                                <input name='firstName' placeholder=' Full Name *'
                                    className='py-3  text-sm bg-slate-100 outline-none rounded-md px-5 w-full'
                                    required
                                />
                            </div>
                            {/* <div className='md:w-1/2 w-full'>
                                <label for="lastName" className='text-sm'>Last Name</label> <br />
                                <input name='lastName' placeholder='first name'
                                    className='py-3  text-sm bg-slate-100 outline-none rounded-md px-5 w-full'
                                    required
                                />
                            </div> */}
                            <div className='w-full md:w-1/2'>
                                {/* <label for="phn" className='text-sm'>Mobile / Phone</label> <br /> */}
                                <input name='phn' placeholder='Phone *' type='phone'
                                    className='py-3  text-sm bg-slate-100 outline-none rounded-md px-5 w-full'
                                    required
                                />
                            </div>

                        </div>

                        <div className='flex md:gap-8 gap-3 flex-wrap  md:flex-nowrap mt-5'>
                            <div className=' w-full'>
                                {/* <label for="email" className='text-sm'>Email</label> <br /> */}
                                <input name='email' placeholder='Email *'
                                    type='email'
                                    className='py-3 text-sm bg-slate-100 outline-none rounded-md px-5 w-full'
                                    required
                                />
                            </div>
                        </div>

                        <div className=' w-full mt-5'>
                            {/* <label for="message" className='text-sm'>Message</label> <br /> */}
                            <textarea name="message" rows="7" cols="50"
                                className='py-3 resize-none  text-sm bg-slate-100 outline-none rounded-md px-5 w-full'
                                required
                                placeholder='Message *'
                            />
                        </div>

                        {/* this is the next page after submit the from */}
                        <input type="hidden" name="_next" value="http://demotrans.com.bd/" />
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_template" value="table" />

                        <button
                            type='submit'
                            className='py-3 mt-5 resize-none  text-sm text-white sentBtn outline-none rounded-md px-5 w-full'
                        >Send</button>
                    </form>
                </div>
            </div>
        </div >

    )
}
