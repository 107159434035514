import React, { useEffect, useState } from 'react'
import { FiPhoneCall } from 'react-icons/fi';
import { BsFacebook } from 'react-icons/bs';
import { SiYoutubemusic } from 'react-icons/si';
import { TiSocialLinkedinCircular } from 'react-icons/ti';
import { AiOutlineClockCircle, AiOutlineMail } from 'react-icons/ai';
import NavtopItem from './components/NavtopItem';
const NavTop = () => {

    const navTopItem = [
        {
            id: 1,
            Icon: FiPhoneCall,
            text: '+88 - 01321123945'
        },
        // {
        //     id: 2,
        //     Icon: AiOutlineClockCircle,
        //     text: 'Sun - Fri 7:00 - 17:00'
        // },
        {
            id: 3,
            Icon: AiOutlineMail,
            text: 'info@demogroup.net'
        },
    ]

    const [dateState, setDateState] = useState(new Date());

    useEffect(() => { setInterval(() => setDateState(new Date()), 1000); }, [])

    return (
        <div className='custom_bg  py-1'>
            <div className='custom_container flex flex-wrap justify-between items-center text-white'>
                <div className='flex align-middle h-full flex-wrap'>
                    {
                        navTopItem.map(navtopItem =>
                            <NavtopItem
                                key={navtopItem.id}
                                Icon={navtopItem.Icon}
                                text={navtopItem.text}
                            />
                        )
                    }
                    <div className='flex align-middle items-center'>
                        <AiOutlineClockCircle className='mr-2' />
                        <p>
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                            })}</p>
                    </div>
                </div>
                <div className='hidden md:block md:flex items-center'>
                    <a href="https://www.facebook.com/demotransbd" target="_blank"><BsFacebook size={20} className='mr-3' /></a>
                    <a href="https://www.linkedin.com/company/demotransbangladesh/" target="_blank">
                        <TiSocialLinkedinCircular size={25} className='mr-3' />
                    </a>
                    <a href="https://www.youtube.com/channel/UCx1ZpCU8g4bKpLQNjmnK54w" target="_blank">
                        <SiYoutubemusic size={20} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NavTop