import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { TiSocialLinkedinCircular } from 'react-icons/ti'
import { TbBrandYoutube } from 'react-icons/tb'
import { Link } from 'react-router-dom'

import menuData from '../../DataBaseFile/menuData'

const Footer = () => {
    return (
        <div className='bg-black py-16 text-white '>
            <div className='flex justify-between custom_container flex-wrap '>
                <div>
                    <p className='uppercase text-xl mb-2'>Corporate</p>
                    {
                        menuData[0].submenu.map(sbmenu =>
                            <Link to={`details/${sbmenu}`} className='uppercase py-1 block'>
                                {sbmenu}
                            </Link>)
                    }
                </div>
                <div className='md:mt-0 mt-3'>
                    <p className='uppercase text-xl mb-2'>get connect</p>
                    <p className='text-sm'>+880-1321123945</p>
                    <p className='text-sm'>info@demogroup.net</p>
                    <p className='uppercase text-xl mt-2'>Follow Us</p>
                    <div className='flex mt-2 items-center'>
                        <a href="https://www.facebook.com/demotransbd" target='_blank'>
                            <BsFacebook size={25} className='mr-3 cursor-pointer' />
                        </a>
                        <a href="https://www.linkedin.com/company/demotransbangladesh" target='_blank'>
                            <TiSocialLinkedinCircular size={35} className='mr-2 cursor-pointer' />
                        </a>
                        <a href="https://www.youtube.com/channel/UCx1ZpCU8g4bKpLQNjmnK54w" target='_blank'>
                            <TbBrandYoutube size={35} className='cursor-pointer' />
                        </a>
                    </div>
                </div>
                <div className='uppercase md:mt-0 mt-2'>
                    <p className='uppercase text-xl'>Schedule</p>
                    <ul className=''>
                        <li className='py-1'> </li>
                        <li className='py-1'> usa</li>
                        <li className='py-1'> latin america</li>
                        <li className='py-1'>europe & med</li>
                        <li className='py-1'>Australia</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer