const mainContent = [
    {
        id: 1,
        title: 'Chairperson’s Message',
        content: `Demotrans has the pleasure to show gratitude to all of you for continual co-operationin business. It congratulates you all for being with receiving the services from one of the leading freight forwarders and customs brokers of Bangladesh.<br/><br/>

        We have every reason to believe that the satisfaction and faith of the customers are the two preliminary bases of its development. It has proved its potentiality and trust worthiness for the customers, over the years through nooks and corners of the world.<br/><br/>
        
        Demotrans optimizes the gain for the customers recognizing them as its masters. Its success lies behind the earnestness for improvisation and innovation. It is a matter of pleasure that all the stakeholders always appreciated and inspired by their active partaking in doing business with Demotrans.<br/><br/>
        
        We champion the transparency of management, accountability of dealings and fulfillment of all the promises made by it. This is the repute for what the authority of the firm warmly celebrates a great unification with all the stakeholders.<br/><br/>
        
        Our aim is to provide the clients with ground-breaking, gainful and the finest freight forwarding solutions.In addition, as a respected corporate citizen, we aim at realizing all of our social responsibilities and make positive contributions to the society.We are professional and profitable organization;nevertheless a good portion of our profit is donated to the field of research on cancer. Besides, tree plantation, beautification of the capital city, lending a hand for the destitute. We invite you all to join with our team to create awareness of cancer and support survivors throughout the world.<br/><br/>
        
        We are known and respected for our reliable, flexible and eco-efficient services.We look forward to involving you, as either a customer or as any type of stakeholder.Our challenge is to become the best global logistics provider, growing side by side with our customers, which will never stop.<br/><br/>
        
        Demotrans believes in customer-oriented service that has been the driving force in expanding our business and reinforcing our corporate values. Therefore, it entertains at the same time, any single suggestion or ample criticism.Ultimately, we feel proud to a firm that we are yours. <br/><br/>`,
        img: 'https://i.ibb.co/g73TNHj/demotrans-2.jpg',
    },
    {
        id: 2,
        title: 'History & Growth',
        content: `In line with the dream of our Chairperson, Demotrans started as worldwide logistics service provider to make hope for cancer survivors. Since its inception, Demotrans has been endeavoring to become one of the leading specialist logistics and supply chain companies in Bangladesh. By the course of time and with the flow of business operations it has proved its existence domineering over the competitive firms by and large through advancing in high quality client portfolio across a wider range of industrial sectors both in domestic and international arena.<br/><br/>

        The Chairperson started our company with a one-room-office with a single counter. Afterwards, simultaneously the earnestness of the authority and the stakeholders facilitated to receive the broader scopes and offers of large-volume-contracts and thus expedited to gain the greater level of appreciation as a globalized company providing services in multifaceted aspects of relocation, transportation,warehousing and logistics.<br/><br/>
        
        Now Demotrans is one of the leading freight forwarding companies in Bangladesh, having the Corporate Head Office in Dhaka, Operation Offices both in Chittagong and Khulna, the seaport cities of the terrain as well as establishing separate Air Operation Office in Dhaka International Airport with hundreds of employees along with thousands of stakeholders and millions of expected well-wishers who are the subject to CSR of this firm.<br/><br/>
        
        With experiencing the events in favour of ours, we dared to take the challenge of providing the services regarding clearance in customs. It is a matter of pride that, we took the optimized decisions of carrying this section of business out,with the fullest compliance of the laws, rules, regulations and policies of the country, exceptionally unlike the other firms providing this particular service, for the greater interest of the country and the people. Our optimized decision has proved its righteousness and decency.<br/><br/>
        
        We took our motto of being patriotic; but now we believe in philanthropism. The human sufferings distress us and we feel to do at our best. With this tempo, we started working in collaboration with some transnational and multinational operators and serving the customers over the world. We areventuring, at every moment, to excel our services and to increase our operations through the world. In course of this attempting, we have launched our first Exterior Operation Office in Edinburgh, Scotland in March 11, 2013, named as Demo Home Limited.<br/><br/>
        
        We have a very strong and positive decision to establish another Operational Office at Cox’s bazar, based on the projected Deep Sea Port in the Bay of Bengal to expedite the international standard of freight forwarding. Apart from that, we have already undertaken the program of starting the attached office of handing the business of internal transportation services. The chronicle of Demotrans is fully an exception in a country like Bangladesh. As Bangladeshes are always unpredictable and indomitable, so we bear these two characteristics in flourishing our business. Thus, having the national spirit in us will surely lead the firm to reach our goal having the mission forwarded and the vision exposed.
        <br/><br/>`,
        img: 'https://i.ibb.co/RT2dw1y/growth.jpg',
    },
    {
        id: 3,
        title: 'Strategy',
        content: `In pursuit of our vision, corporate strategies focus on breeding the superior returns for its stake holders calculated in terms of growth in revenue, returns on capital engaged.

        <br/>For that, Demotrans has already developed some strategic initiatives and has undertaken various programs. The following ones speak out for the appropriateness of our planned view points.
        <br/><br/>
        Foundation of a holistic service-rendering capacity; <br/>
        Extension of the international standard of supply chain management;<br/>
        Strive for functional excellence;<br/>
        Drive for generation of efficiency;<br/>
        Supply of proficient workforce;<br/>
        Delivery of superior service;<br/>
        Preservation of expertise;<br/>
        Proliferation of organizational growth initiatives;<br/>
        Sustenance of growth momentum;<br/>
        Maintenance of the occupational safety compliance;<br/>
        Development of innovative technology solutions;<br/>
        Improvement of team working spirit;<br/>
        Primacy of CSR and gender issue;<br/>
        Supremacy of eco-friendliness;<br/>
        Incorporation of ICT and electronics;<br/>
        Accommodation of international culture;<br/>
        Marketing through all communications;<br/>
        Branding with the standard of service;<br/>
        Integrity for trustworthiness;<br/>
        Sincerity for professionalism;<br/><br/>
        At the end of the day, relationship with the people champions in our business.<br/>`,
        img: 'https://i.ibb.co/YyJ5JPM/stretegy.jpg',
    },
    {
        id: 4,
        title: 'Core Values and Vision',
        content: `Demotrans visions to sustain as one of the leading logistics providers of the land, belonging to the nuclear values together with its key strategic competitive advantages. These are people, bondage,safety, choice, brand, innovation, integration, infrastructure and environment.

         <br/><br/><b>People</b><br/>
        In Demotrans, people are a critical ingredient to our success.

        <br/><br/><b>Bondage</b><br/>
        Demotrans endeavours for building everlasting bondages with all the customers, suppliers and other stakeholders.
        
        
        <br/> <br/><b>Safety</b><br/>
        Safety comes first in Demotrans. Occupational health and safety adding with risk management initiatives are some of the prime concerns of the firm.
        
       <br/><br/><b>Preference</b><br/>
        Demotrns has a fine access to a broad network of road, air, sea and rail means best tailored to the needs of the customers.
        
        <br/> <br/><b>Brand</b><br/>
        For integrity, honesty, reliability, innovation, trust and for ‘coming through’ for customers, Demotrans is the ultimate solution.
        
        <br/> <br/><b>Innovation</b><br/>
        Our efforts for innovation are expected to pave the way for grand prospect in this service sector.
        
        <br/><br/><b>Integration</b><br/>
        It can structure integrated solutions and logistic supports that maximize the efficiency level across all elements of the supply chain.`,
        img: 'https://i.ibb.co/QrwW84V/values.jpg',
    },
    {
        id: 5,
        title: 'Company Policies',
        content: `
        <br/><b>Code of Functioning</b><br/>
        Demotrans has the pleasure to introduce“Code of Functioning” (hereinafter called as ‘the CoF’) that elaborates the ethical issues.That employees prone to exhibit and fix a stance that is named aftermath is, the ethics of the organization. Demotrans tends to revolutionize the spirit of ethical strength in business.Just trading, equal prospect, health and quarantine, safety and blossom, in conjunction with the environment, the CoF never excludes from its fortitude.
        
        <br/><br/><b>Gender-friendlyRecruitment and Diversity Policy</b><br/>
        Demotrans accepts as true that a brilliant and sundry workforce is one of the key competitive advantages and its achievement is a reflection of the excellence and accomplishment of our people.The CoF belongs to the commitment to providing equal employment amenities and furthering an atmosphere that welcomes, magnetizes and maintains a variedrange of workforce, and to recognizing human being equitably with dignity, respect and humanity.
    
        <br/>
        Regardless of nationality, age,race, sex, ethnicity, family status, marital status, religious beliefs, political views,physical challenges, pregnancy or forthcoming pregnancy, breast feeding, personal affiliations, or cultural background, it offers the uniform set up for all the employees. It always welcomes the variety of approaches and ideas from the workforce in case of excelling the service of the firm.
        
        <br/><br/><b> No Child Labour</b><br/>
        Demotrans recognizes that progressive labour policies are good business practices, especially for a company like us that has global ambition. We do not allow any female employees during night shift operations & we strictly prohibit any form of forced labour. We actively pursue the policy of No child labour in Demotrans.Our HR policies have been continuously up-to-dated.
        
        <br/><br/><b> FunctionalEthics</b><br/>
        Demotrans appreciates the ethical conduct and performs constantly with its values and beliefs. It is dedicated to the highest degree of honesty, integrity, transparency and accountability. Just dealing intrading, complying with the law, consolidating bondage with the stakeholders and above all,positioning on ethical strengths are the foci of the organization.
        
        <br/><br/><b>Principles of Compliance</b><br/>
        Demotrans, being an integrated transport and logistics firm,it aims at providing our customers with the highest feasible level of services,having no compromises for the safety of our employees or of the public.
        
        <br/>
        It further targets,at being the pioneer in our subject markets through implementing entirely incorporated management system that comprises of every legal and statutory requirements; and in the pursuit of running development, at having an extraordinary repute and eminent position in case of legal compliance.
        
        <br/><br/><b>Health and Quarantine</b><br/>
        Demotrans hoists the flag of hygienic authenticity.This flag will be containing the bright colours of operational safety and quarantine issues encircled with occupational health; adopting the risk management initiatives, it shall be gripping hazard free atmosphere. It shall provide the trainings and instructions, shall manage to disseminate the information and essential resources to support OHS in the various areas of rendering service.
        
        <br/><br/><b>Environmental Principles</b><br/>
        Demotrans shall be complying with the environmental laws and regulations. It has every reason to believe that environmental issues come before operations. A clear understanding on the risks to vital environmental agenda is the earnest endeavour of the firm. It shall develop a culture of sustainable environmental management by developing the awareness and involvement, implementing systems for environmental management, setting objectives and associated measurement systems to enable continuous improvement processes.
        
        <br/><br/><b>Energy Objective</b><br/>
        Demotrans undertake all the activities that consume or tend to consume energy within the comprehensive management of energy. It acts to reduce the usage and costs of energy. It,keenly considering the use of alternate energy spring as it becomes economically viable, commits to establishing unit-specific energy management strategies.
        
        <br/><br/><b>Confidentiality Management</b><br/>
        Demotrans provides the customers with an array of integrated logistic supports. It interacts with the clients and other stakeholders according to the respective business conducts.In addition to ancillary activities, protecting personal information provided to it is important.
        
        <br/><br/><b>Personal Information Collection</b><br/>
        Demotrans, as an incorporated provider of logistics, in its capacity collects private and sometimes, confidential information about its employees, clients, suppliers, sub-contractors and other stakeholders, in order to conduct its business with efficiency and effectiveness. This collection shall help the firm in its worldwide networking for rendering services.
        
        <br/><br/><b>Professional Ethics</b><br/>
        It believes on the quality and reliability of its services, based upon which it can develop long run affairs with our stakeholders and as a result, achieves its objective of sustainable growth and maintainable security.
        
        <br/><br/><b>Transparent Business Ethics</b><br/>
        Transparency & business ethics is the core value of Demotrans and it is strictly followed at every step of the business processes. Every business in Demotrans is modeled in a way that enables the management to ensure highest degree of both financial and behavioral accountability. Demotrans pursues Zero Tolerance policy against all kinds of corruption.
        
        <br/><br/><b>Lunch Facility</b><br/>
        Healthy food prepared and served fully at company’s cost at the same time ensuring highest standard of hygiene factor.
        
        <br/><br/><b>Transportation Facility</b><br/>
        Pick-up and drop services by company’s transport which cost bear 50/50 between company and employee, the service is more comfortable, safe and timely.
        
        <br/><br/><b>Loan facility</b><br/>
        Any permanent employees can take loan to purchase necessary home appliances at nominal interest rate and this fund is operated and managed by a committee of workers’ representatives.`,
        img: 'https://i.ibb.co/cwpLdVg/polices.jpg',
    },
    {
        id: 6,
        title: 'career',
        content: `Recruiting top talent is one of the key elements to Demotrans’ success. Our company attracts individuals who are highly motivated, flexible, and results-oriented. As Demotrans rises to meet the demands of a global economy, there are many opportunities to use your skills and talent to grow with us. A great career at Demotrans may be in your future.<br/><br/>

        You may let us know your interest or send your bio data and we will keep this in our records to contact with you. 
        <br/><br/>
        Demotrans <br/>
        Corporate Headquarter <br/>
        Tel: +88-02-48961424-26 <br/>
        Fax: +88-02-48961425 <br/>
        Email: <a href="mailto: info@demogroup.net " style={color:'red'}>info@demogroup.net </a><br/>`,
        img: 'https://i.ibb.co/9hBjfdD/career.jpg',
    },

    // after this the service content is start 
    {
        id: 7,
        title: 'Freight Forwarding',
        content:
            `
            <b>Ocean Forwarding</b><br/>
            This portion offers a complete scope of global ocean freight and transportation services for the goods of the customers. We process and issue the documents for the Bills of Lading via booking process from the ocean carrier. This part includes the documentation and submission the IGMs (Import General Manifests)for the customers.<br/><br/>
            
            <b>Air Forwarding</b><br/>
            This portion offers a complete scope of global ocean freight and transportation services for the goods of the customers. We process and issue the documents for the Bills of Lading via booking process from the ocean carrier. This part includes the documentation and submission the IGMs (Import General Manifests)for the customers.<br/><br/>
            
            <b>	Multimodal Services</b><br/>
            It provides the clients with multi-transportation using all the simple, complex and compound combinations of the mode of transportation like Sea, Air, Railway and Highway (e.g. Sea-Air, Sea-Railway, Sea-Highway, Air-Railway, Air-Highway,Railway-Highway, Sea-Air-Railway, Sea-Air-Highway, Air-Railway-Highway and Sea-Airway-Railway-Highway).This is the most efficient and cost-effective in respect of both time and money<br/><br/>
            
            <b>Customs Clearance</b><br/>
            This section includes the declaration services to customs authority for export and import. It also provides extended services for rebate, refund, duty drawback and credit.<br/><br/>
            
            <b>Pick and Delivery</b><br/>
            It comprises of, due to the customer’s convenience, door-to-door services and speed services for the hot deliveries.<br/><br/>
            
            <b>Consolidation</b><br/>
            This part offers the opportunity of saving costs by consolidating several vendors. Apart from that, cross dock and order management accompanies transport management. It ensures the safe, rapid and economical transporting of household goods.<br/><br/>

            <b>GOH Service</b><br/>
            Also known as hangtainer, it stands for the desiccated containers of namely three types (e.g. String, Strap and Bar System) that is converted or outfitted for safe and convenient transportation of garments on hangers. It is usually built maintaining three particular formats (i.e. one tier, two tiers and three tiers) and in terms of length or volume, the twenty-foot-containers with 11 (eleven) bars and forty feet by forty feet high cube with 22 (twenty two) bars. It is specially designed and equipped with silica gel, for eliminating moisture, and made of synthetic, cotton or nylon cords.<br/><br/>

            <b>Pallets Service</b><br/>
            It is initiated with the advent of containerization and standardization in intermodal shipping container industry along with the additional standards in pallet manufacturing. Quality packing of goods is ensured through standardized pallets as well as choosing the correct size can save you time and money. The following types, this service offers.(#) Wooden Pallets, (#) Plastic Pallets, (#) Galvanized Steel Pallets, (#) Other Metal Pallets.
            <br/><br/>

            <b>Moving Service</b><br/>
            It extends the power and quality of its global logistics network to the personal household relocation service.
            <br/><br/>

            `,
        img: 'https://i.ibb.co/87bp4pj/freight-min.jpg',
    },
    {
        id: 8,
        title: 'Contact Logistics',
        content:
            `
            It assures a wide range of contract logistics services like trucking services to value-added services. This class one service offers not only basic transportation and storage services but also specialized value-added services. <br/><br/>

            <b>Warehousing</b><br/>
            It provides the inventory management for both short and long term stocks and VMI services. It also includes providing visibility of product receiving/issuing, inventory level via WMS.<br/><br/>
            
            <b>Transportation</b><br/>
            It records the inland transportation, distribution services and hot services. It represents the fastest door-to-door service with the shortest transit, both for standard services as well as special transports, like heavy or oversized goods.<br/><br/>
            
            <b>	Value-added services</b><br/>To meet the high demand for specialist expertise Demotrans created their own Group Division focusing solely on value added services that would complement existing products. It consists of repackaging, labeling, bar coding, kitting and customized service complying with the requirements of the customers.<br/><br/>

            `,
        img: 'https://i.ibb.co/5sd54k8/contract.webp',
    },
    {
        id: 9,
        title: 'LTL-FTL',
        content:
            `
            Our well-known mutual partnerships facilitate groupage and distribution, transport within Bangladesh as well as internationally. Our services cover every corner of Bangladesh and our locations has join forces professional partners with some of the best carriers nationwide, negotiated the best possible rates and developed tools to allow shippers to easily manage the shipping process.
            <br/><br/>

            <b>LTL</b><br/>
            For shipments that will not fill an entire truck, less than truckload (LTL) offers supreme convenience and cost savings for domestic shipping. This is a cost-efficient alternative to individually packaged shipments with maximum flexibility and reliability.
            <br/><br/>
            
            <b>FTL</b><br/>
            FTL(Full Truck Load) represents the fastest door-to-door service with the shortest transit, both for standard services as well as special transports, like heavy or oversized goods.
            <br/><br/>
            
            <b>	Security Planning</b><br/>
            Our Security Planning includes procedures for each shipment covering:<br/>
            Our Security Planning includes procedures for each shipment covering:<br/>
            Shipment Disruptions;<br/>
            Shipping Delays;<br/>
            Unexpected Events;<br/><br/>

            <b>	Managed Service</b><br/>
            Later pick ups;<br/>
            Each shipment personally monitored;<br/>
            In-transit updates;<br/>
            Notification of potential delays;<br/>
            Nationwide shipment rescue options;<br/>
            Consignee notification;<br/>
            Scheduled delivery;<br/>
            Tracking and tracing;<br/>
            <br/><br/>

            <b>Services include</b><br/>
            Responsive Personalized Service from Professional, Experienced Staff;<br/>
            Reliable, Efficient, Cost Competitive Transportation Services;<br/>
            On-time deliveries;<br/>
            Customs clearance;<br/>
            Competitive insurance rates;<br/>
            Hazardous cargo transportation;<br/>
            Dry, refrigerated fresh or frozen safe and on time service;<br/>
            Industry-specific transport such as textile logistics, retail logistics and press logistics;<br/>
            Multi-modal transportation such as road, rail and barge;<br/>
            Packing/Repacking;<br/>
            Labeling and palletizing;<br/>
            Warehousing, Cross-Docking, Pick and Pack, Kitting and Distribution Services;<br/>
            Consolidation and De-Consolidation Services;<br/>
            Door To Door Service;<br/>
            Reduced Damage and Loss Claims;<br/>
            Nationwide network of carriers;<br/>
            <br/><br/>

            `,
        img: 'https://i.ibb.co/HnmGKNS/LTL-min.jpg',
    },

    {
        id: 10,
        title: 'Project Cargo',
        content:
            `
            It provides the standard project cargo services for the EPC (Engineering, Procurement and Construction) and the plant customers with special shipping requisites pertinent to out-of-gauge, oversized and heavy cargoes with special care and protection
            <br/><br/>

            <b>Oversized & Heavy Cargo</b><br/>
            It provides the standard project cargo services for the EPC (Engineering, Procurement and Construction) and the plant customers with special shipping requisites pertinent to out-of-gauge, oversized and heavy cargos with special care and protection.
            <br/><br/>
            
            <b>Bulk Chartering</b><br/>
            It consists of voyage chartering service, stowage plan and review accompanied with scrutinizing the limitations of the destination port and the to-dos.
            <br/><br/>
            
            <b>	Job site Managerment </b><br/>
            It provides the pre-survey for transporting and transportation consultancy along with the arrangement of documents, customs clearance and duty exemption, cargo supervision during transporting, as well as in transit and secures a delicate workplace.<br/><br/>

            `,
        img: 'https://i.ibb.co/M7Mwp4Z/project-Cargo-min.jpg',
    },


    {
        id: 11,
        title: 'Reefer Cargo',
        content:
            `
            It has a complete mode of reefer cargo services that ensures the due-time release of fast-moving drugs and vaccines or perishable goods to destined places all over the world. It is specially designed to provide room for the frozen and chilled products using refrigerated containers and suitable stuffing. The reefer units are well-equipped with microprocessor controllers and mechanical flowcharts. Due to the diversified requisites of the clients, Controlled Atmosphere(CA) containers are used that retain perfect conditions for delicate and sensitive agricultural or veterinary products.The features of this service are as follows.
            <br/><br/>

            State-of-the-art fleet of reefer containers;<br/>
            Sufficient equipmental support;<br/>
            Absolute control of temperature using digital electronic system;<br/>
            Remote monitoring system on the route;<br/>
            Use of environmental refrigerant;<br/>
            Improvised protection system against cargo mess up;<br/>
            Extra care for the containers at the transit points;<br/> <br/><br/>

            <b>Checklist for Reefer Cargo</b><br/>
            Ensure only clean reefer container is accepted from depot <br/>
            Check that unit is set at the correct carrying temperature <br/>
            Check that ventilation is set at the correct level in cbm/hour (for frozen cargo, ventilation is always CLOSED) <br/>
            When using Automatic Ventilation – check that AV+ is activated <br/>
            Check that Humidity Control is set at the correct level (for frozen cargo humidity control is always OFF) <br/>
            Observe the use of adequate packaging material<br/>
            Cargo should be pre-cooled to the carrying temperature (with only minor deviations, depending on the commodity)<br/>
            Airflow should not be restricted by the cargo or packaging material<br/>
            Do not stuff cargo above the height of the red load-line<br/>
            Container doors should be closed immediately after the stuffing has been completed and a seal securely affixed<br/>
            Container should be plugged into a stable power source as soon after the stuffing as possible<br/><br/>

            <b>Stowing the Container</b><br/>
            Stowing the cargo must be done quickly and efficiently and the surface/pallet packing must bekept intact.<br/>
            The surfaces/pallets should not be stacked by a fuel motor forklift as its gas emissions may damage the cargo.<br/>
            If the packing is damaged during stowage, do not load the produce.<br/>
            Damaged fruit may have a bad effect on the rest of the fruit in the container.<br/>
            The container should be opened as close as possible to the cold warehouse. The refrigeration unit should not be operated prior to/or during stowing.<br/>
            After closing the container, the refrigeration unit should be activated.<br/>
            The only person authorized to lock the container with a security seal is the storage person (not ​a driver or any other party). The container seal number must not be uncovered and should be clear and legible<br/><br/>
            
            <b>To Ensure Uninterrupted Airflow</b><br/>
            Do not leave excess space between the cargos.<br/>
            Do not leave excess space in front of the door.<br/>
            Do not leave excess space in front of the machinery.<br/>
            Leave enough space between the cargo and the doors to allow unhindered air circulation.<br/>
            Do not stow goods above the red line.<br/>
            The air vents should be set according to your requirements.<br/><br/>

            <b>Before Moving the Container to the Port/Terminal, Ensure that</b><br/>
            The container is in good working order.<br/>
            If you have ordered a generator, ensure that it is in good working order and that the fuel tank is full.<br/>
            The temperature has been set as required.<br/>
            Air vent openings have been set as required.<br/>
            The container is sealed with a high security seal.<br/>
            The stickers are applied as required.<br/>
            The driver is instructed that it is his duty to ensure that the container is working throughout the land transportation leg and that in case of any mishap he must report the incident  immediately and receive proper instructions.<br/><br/>
            
            <b>Ensure that at Port/Terminal</b><br/>
            During the waiting period at the entrance to the terminal/port the container is in good working order.<br/>
            After discharge at the terminal/port, the container is connected to the electricity source immediately.<br/><br/>

            <b>Sensitive Care in Cargo Stuffing</b><br/>
            <b>Suitable Loading and Maintenance of Airflow</b><br/>

            <b>Consequence: This space ensures out-of-the-way to the end.</b><br/>
            <b>Front-side Placement of the Goods</b><br/>

            <b>Consequence: This stops the circulation of the inner air.</b><br/>
            <b>Rear-side Placement of the Goods</b><br/>

            <b>Consequence: This prevents the failure of cooling and successive damages.</b><br/>

           <br/><br/>


            `,
        img: 'https://i.ibb.co/j8gxgK0/Reefer-Cargo.jpg',
    },

    {
        id: 12,
        title: 'Dangerous Cargo',
        content:
            `
            This portion presents the privilege of possessing the express, perfect and standard shipping tune-up and also provides the safe, pragmatic and substantiated movement of dangerous cargoes. The elements to comply with are as follows.
            <br/><br/>

            MARPOL 73/78 Appendix I for the specification of oil;
            The IGC Code for gas;<br/>
            The IBC Code & MARPOL 73/78 Appendix II for hazardous liquids or fluids, chemical; complexions accompanied with waste specified in;<br/>
            The IMDG Code for marine pollutants and wastes;<br/>
            The BC code Appendix B for dangerous bulk solid materials;<br/>
            <br/><br/>
            
            <b>Services Include</b><br/>
                DGSA Consultancy<br/>
                Packing and Documentation of Dangerous Goods<br/>
                HSE and Safety Data Sheet Advice<br/>
                Air Express – Recommended in all instances. For a fast, standard and seamless transportation service this never disappoints. We can deliver on any basis required, door to door, door to port or port to door.<br/><br/>
                Ocean Express – Accordance with the IMDG regulations service is available on ocean express.<br/>
                Road Express – Through utilizing credible and secure road networks, able to load consignments and distribute within a reasonable time frame and reasonable price.<br/>
                Pharma Express – quick delivery service tailored for biological, temperature controlled, time sensitive and cryogenic consignments. The standard service encompasses a door to door solution unless otherwise specified.<br/>
                EQ Express – Cost effective solution for small samples that need to be delivered within a strict time frame.<br/>
                Isotope Express – Designed specifically around the fragile nature of transporting class 7 radioactive materials. This can include consignments of recyclable waste, isotopes for laboratory use in diagnosis or articles and substances used for X-ray purposes.
                Secure Express – Service for high value consignments and materials of an explosive nature. This gives added security, through strategic planning and caution.<br/>
                Always update to customers about the progress of the shipments, without being in the embarrassing situation of them having to notify us of a specific problem first.<br/>
                
                <br/><br/>

            `,
        img: 'https://i.ibb.co/pQks18h/DANGEROUS-CARGO.jpg',
    },

    {
        id: 13,
        title: 'Supply Chain Logistics',
        content:
            `
            Demotrans belongs to a mammoth concern of supply chain. Pioneering the forwarding of the freights, it has already been capacitated to provide the customers with fully global landmark of SCM.
            <br/><br/>
            We have stood on core competencies in all of the performances namely freight, customs clearance and contract logistics and have been outfitted with cutting edge technology. We commit to the Real time updatation of the loops of the chain. We care for the junctions, transits and shifts in the total mechanism. We resist the contingencies with our well-prepared and properly regulated operational processes.
            <br/><br/>
            Demotrans relies closely on electronic data communication in its functioning. We not only offer highly specialized inventory management and warehousing operations and other value adds like purchase order management (POM),
            <br/><br/>
            We design, plan, execute the services catalogued for niche segments termed as integrated logistics services (ILS). Therefore, we deal with the customers following the same methods, documentation and process. We know how to adapt international processes to suit local situations better.
            <br/><br/>
            Demotranshas the experts with specialization in automotive logistics, aero spares, medical and environmental logistics and other particular segments. You can rely on it for furnishing the complete and successful reach of your product.
            <br/><br/>

           
            `,
        img: 'https://i.ibb.co/vDP9zbC/SUPPLY-CHAIN-LOGISTICS.jpg',
    },

    {
        id: 14,
        title: 'Value Added Service',
        content:
            `
            
            To meet the high demand for specialist expertise Demo Logistics created their own Group Division focusing solely on value added services that would complement existing products.<br/><br/>

            Product-related VAS: Outsourcing Service,Quality control, finishing, pre-processing, pre-assembly / parts production, sequencing, packaging optimization and disposal, container development, container management.<br/><br/>

            Administration and control: Value stream analysis and design, Consultation on Import – Export / Customs Consultancy Services, freight management, industrial planning, call centre, order processing, safety engineering, quality management, staff provision.<br/><br/>

            Logistics-related VAS: Order picking, finishing, co-packing, display assembly / Garments-On-Hanger service, labeling / tagging / bar coding / bar code scanning, quality control, returns management, waste disposal, recycling, customs services.<br/><br/>

            <b>Services Include</b><br/>

            Customs clearance<br/>
            This section includes the declaration services to customs authority for export and import. It also provides extended services for rebate, refund, duty drawback and credit.<br/><br/>

            Inland transportation<br/>
            Our well-known mutual partnerships facilitate groupage and distribution transport within Bangladesh as well as internationally. Our services cover every corner of Bangladesh and our locations has join forces professional partners with some of the best carriers nationwide, negotiated the best possible rates and developed tools to allow shippers to easily manage the shipping process.<br/><br/>

            Warehousing<br/>
            Demotrans arrange to provide the customers with the expected level of effective warehousing facilities which entail a capacity of 56,000 square feet for dry storage. Public, licensed private and off-dock bonded warehouses have an access for four truck doors. Off-dock bonded warehousing capacity limits to 75,000 square feet accordingly in various locations. This warehousing necessarily covers 24 hour monitored fire and burglary security, external armed vigilance, internal patrol, close circuit television (CCTV) and other necessary modern equipments.<br/><br/>

            Insurance<br/>
            Demotrns has received the full-proof insurance policies from its inception. We currently have the businesses with several top insurance companies in Bangladesh and paying premium at a very high rate, we cover the fire policy, burglary policy, natural contingency policy and the road accident policy. With Demotrans you can depend on the reliable flow of goods, just-in-time distribution and comprehensive value added services at all time. As an experienced logistics partner your goods are always at the right place at the right time.<br/><br/>

           
            `,
        img: 'https://i.ibb.co/K0SxVXp/VALUE-ADDED-SERVICE.png',
    },

    {
        id: 15,
        title: 'Facilities and Amenities',
        content:
            `
            Demotrans is earnest in rendering services throughout the world and extends the business to international arena offering some facilities and benefits that are considered by the firm as its assets. These assets primarily include warehousing and insurance. It secondarily offers computerized inventory management and data communication;electronic data interchange (EDI) and web access. Its tertiary proffer comprises of cross docking, pallet racking, bar code scanning, repacking or re-labeling, consolidation, just-in-time (JIT) and ready pick n’pack, quality control platform (QCP), immaculate container load plan (CLP) and zero escorting charge.
            <br/><br/>

            <b>Warehousing</b><br/>
            Demotrans arrange to provide the customers with the expected level of effective warehousing facilities which entail a capacity of 56,000 square feet for dry storage. Public, licensed private and off-dock bonded warehouses have an access for four truck doors.Off-dock bonded warehousing capacity limits to 75,000 square feet accordingly in various locations. This warehousing necessarily covers 24 hour monitored fire and burglary security, external armed vigilance, internal patrol, close circuit television (CCTV) and other necessary modern equipment.<br/><br/>

            <b>Customs clearance</b><br/>
            Incomplete or wrong customs documentation can cause considerable delays in transportation, reducing supply chain and delivery reliability. To make faster and smooth value added service Demotrans started to provide customs clearance service for their customers. This section includes the declaration services to customs authority for export and import. It also provides extended services for rebate, refund, duty drawback and credit.<br/><br/>

            <b>Insurance</b><br/>
            Demotrans has received the full-proof insurance policies from its inception. We currently have the businesses with several top insurance companies in Bangladesh and paying premium at a very high rate, we cover the fire policy, burglary policy, natural contingency policy and the road accident policy.<br/><br/>

            The others are those structured and dedicated amenities that a customer must enjoy and realize.

           
            `,
        img: 'https://i.ibb.co/4tVk5sF/facelity.webp',
    },


    {
        id: 16,
        title: 'Customs Clearance',
        content:
            `
            Incomplete or wrong customs documentation can cause considerable delays in transportation, reducing supply chain and delivery reliability. To make faster and smooth value added service Demotrans started to provide customs clearance service for their customers. This section includes the declaration services to customs authority for export and import. It also provides extended services for rebate, refund, duty drawback and credit. Services include:
            Automated customs clearance;<br/><br/>
            `,
        img: 'https://i.ibb.co/VCvKksK/CUSTOMS-CLEARANCE.jpg',
    },
    {
        id: 17,
        title: 'Warehousing',
        content:
            `
            Demotrans arrange to provide the customers with the expected level of effective warehousing facilities which entail a capacity of 46,000 square feet for dry storage. Public, licensed private and off-dock bonded warehouses have an access for four truck doors. Off-dock bonded warehousing capacity limits to 75,000 square feet accordingly in various locations at port area. This warehousing necessarily covers 24 hour monitored fire and burglary security, external armed vigilance, internal patrol, close circuit television (CCTV) and other necessary modern equipment.
            <br/><br/>

            Facilities Include:<br/>
            The facility is ISO 9001:2000 certified.<br/><br/>

            Customs Bonded Warehouse.<br/><br/>

            Proper ventilation and temperature controlled facilities<br/><br/>

            20 loading platforms for on-chassis stuffing and unloading from Covered Vans and Trucks.<br/><br/>

            Separate Jute Shed for stuffing of Jute, Jute Goods and other similar export cargo.<br/><br/>

            Hanger cargo arrangement with adequate hanger trolleys for expeditious stuffing of such cargo.
            <br/><br/>

            Cargo Inspection Center prior to stuffing has been set up with permission of the Customs Authority.<br/><br/>

            10 Reefer Plugging Points.<br/><br/>

            Uninterrupted power supply supported by standby Generator. Especially for the reefer containers. There is sufficient illumination for smooth operation.<br/><br/>

            Coverage of close circuit television (CCTV). A CCTV monitoring room has been set up for round the clock monitoring of the CCTV covered area. 90 days backup is maintained for investigation purpose.
            <br/><br/>
            Equipped with modern fire fighting portable equipment and fire hydrant.<br/><br/>

            Insurance coverage against cyclone and fire.<br/><br/>

            Multistage photography is maintained throughout the stuffing operation.<br/><br/>

            GPS based Vehicle Tracking System has been set up with a control room manned round the clock. Steel protection barriers have been fixed to all trailers so that doors of the laden containers cannot be opened during transit between ICD and the Port.<br/><br/>

            `,
        img: 'https://i.ibb.co/4tVk5sF/facelity.webp',
    },

    {
        id: 18,
        title: 'Insurance',
        content:
            `
            Demotrans handles every shipment with the highest care and attention. Part of our service is the availability of skilled advice on dangers and risks during the time of transit and storage. It is a primary obligation under every international sale of goods contracts that either the seller or the buyer will have to arrange sufficient insurance for the goods in accordance with the agreed shipment terms.<br/><br/>

            Demotrans has received the full-proof insurance policies from its inception. We currently have the businesses with several top insurance companies in Bangladesh and paying premium at a very high rate, we cover the fire policy, burglary policy, natural contingency policy and the road accident policy.<br/><br/>

            The others are those structured and dedicated amenities that a customer must enjoy and realize.
            <br/><br/>
            <b>Insurance Include</b><br/>
            Fire;<br/>
            Explosion;<br/>
            Storm, flood and other weather hazards or natural contingency;<br/>
            Washing overboard in heavy sea;<br/>
            Leakage;<br/>
            General average claims for sea freight;<br/>
            Services Include<br/>
            All kind of commodities containerized and in bulk;<br/>
            All modes of transport;<br/>
            Combined transports;<br/>
            FCL and LCL shipments;<br/>
            Worldwide coverage;<br/>
            Annual or project policies;<br/>
            Storage coverage;<br/>
            All risk coverage for physical loss or damage;<br/>
            One stop shopping for your freight and insurance requirements;<br/>
            Low premium costs compared to the value of goods in transit.<br/>
            `,
        img: 'https://i.ibb.co/n11mYng/INSURANCE.webp',
    },


    // after this the industry content is start 


    {
        id: 19,
        title: 'Fashion Industry',
        content:
            `
            The fashion industry moves at a fast pace and the industry has sophisticated and demanding consumers who require multi channel offering, brand experience, personalization and increased product differentiation. Fashion industry is characterized by constant shifts in customer demand and short product life cycles.  This leads to shorter product life and increased need for modernization, flexibility and superior order fulfillment certainty. The major needs in the industry include:
            <br/><br/>

            Reducing logistics cost;<br/>
            Sensing and responding to changes in customer demand;<br/>
            Building sustainability and security into supply chains;<br/>
            Managing promotions and new product releases;<br/>
            Improving order fulfillment;<br/><br/>

            <b>Services Include:</b><br/>

            Pick and Pack Handling;<br/>
            Purchase Orders (PO) management;<br/>
            Vendor management and buyers consolidation;<br/>
            Quality control;<br/>
            Sorting by Style, Color, and Size;<br/>
            Bar Coding / Bar Code scanning;<br/>
            Special Handling at Source;<br/>
            Direct to store;<br/>
            Store order fulfillment;<br/>
            Garments on Hanger (GOH);<br/>
            Global air, sea and land freight management;<br/>
            Multimodal transportation;<br/>
            End-to-end supply chain visibility;<br/>
            Consolidation and Deconsolidation;<br/>
            Customs and duty management;<br/><br/>

            <b>Value-added services</b><br/>
            Our value-added services are customized to serve the specialized needs of the apparel industry:<br/><br/>

            <b>Garments-On-Hanger service –</b><br/>
            Specialists in storing, selecting and  preparing hanging garments at origin side to suit the requirements of specific business so that they are immediate sale without the need for ironing which is save time and reduce handling, storage and labor costs.
            <br/><br/>

            <b>Quality control</b><br/>
            Strict monitoring and perform the necessary quality control processes, such as inspection of garments for damage.
            <br/><br/>

            <b>Inventory </b><br/>
            Using the latest technology, we can provide inventory counts throughout delivery.
            <br/><br/>

            <b>Customized packaging </b><br/>
            Configuring products with special hang tags, bundling, tagging, labeling and shrink wrapping as per customer preferences in our DC.
            <br/><br/>

            <b>Delivery optimization</b><br/>
            Guarantee your presence on retailer shelves through delivery techniques we can provide such as direct-store-delivery and Just-In-Time replenishment. With Demotrans you can depend on the reliable flow of goods, just-in-time distribution and comprehensive value added services at all time. As an experienced logistics partner your fashion articles are always at the right place at the right time.  
            <br/><br/>

            `,
        img: 'https://i.ibb.co/3yJXnXp/Fashion-Industry.jpg',
    },

    {
        id: 20,
        title: 'Perishable Industry',
        content:
            `
            Perishable Industry

            Delicate cargoes like fresh and frozen goods must be carefully shipped and constantly monitored and require seamless temperature control along the whole supply chain. Our perishable professionals ensure that your cargo travels in seamless temperature control from the point of origin to the final destination, in close coordination with leading perishables container carriers and service providers.<br/><br/>
            Whether it’s live, chilled or frozen, our perishable professional will give you greater flexibility, certainty and creativity for your perishable freight.
            <br/><br/>

            Experienced and knowledgeable staff ready to advise you;<br/>
            Experts will work with you to provide tailor-made logistics solutions to meet your specific requirements;<br/>
            Guidance on packing for peak freshness and quality;<br/>
            24 hour, 7 day Service Available;<br/>
            Reefer Cooling In Transit Points;<br/>
            Multimodal Perishable Handing;<br/>
            Insurance Cover;<br/>
            Extended Detention & Plug In Free Times; <br/>
            Perishable Documentation;<br/>
            Perishable Customs Brokerage;<br/>

            <b>Basic rules of handling the perishable goods:</b><br/>Perishable cargo may only be accepted for transport if it is possible to reach the final destination in good condition.<br/><br/>

            Transport should be legal according to the law in the port of origin, destination and transshipment ports.<br/><br/>
            
            Package should be properly packed and the package is accompanied by all requested documents.Each package containing perishable goods should be labeled with “PERISHABLE” labels and “This way up” labels if necessary, or with other labels. The packing should protect against folding, keep appropriate temperature, and separate the package from other packages.<br/><br/>
            
            The shipper should be informed about the latest delivery time to the airport before the take off and all reservations must be made and confirmed.<br/><br/>
            
            The shipper should provide written instructions regarding maximum acceptable duration of transportation and any special handling required and instructions should be put on the air waybill as well as on the packages.<br/><br/>
            
            It is forbidden to consolidate PER goods on AWB together with other goods.  Perishable goods should be sent on separate AWB. In the shipment documents (AWB and CM) it should be marked, what kind of perishable goods is in the package.<br/><br/>
            
            The shipper is obliged to put additional information regarding storage conditions, delivery to the receiver, Health certificates and other permissions in “handling information” and all permission should be attached to the AWB. Also every shipment containing flowers, plants, fresh fruit or vegetables must be accompanied by a sanitary certificate issued by authorities of the country of export. 
            <br/><br/>
            `,
        img: 'https://i.ibb.co/s2r3BPY/PERISHABLE-INDUSTRY.jpg',
    },

    {
        id: 21,
        title: 'Healthcare Industry',
        content:
            `
            Biotechnology is currently the fastest growing market within the pharmaceutical industry and the number of temperature sensitive products is increasing. One of the most rigorously regulated of all cargo categories, healthcare products require extra-special care, attention and seamless temperature control along the whole supply chain. Demotrans offers end-to-end visibility and pro-active temperature monitoring and control including temperature controlled packaging solution, GDP validated processes with reliable transportation within own controlled network and supply chain optimization. Demotrans specialists offer comprehensive healthcare logistics design and implementation solutions.
            <br/><br/>
            <b>Healthcare offers:</b><br/>

            Quality management system modeled on healthcare standards;<br/>
            Dedicated industry specialized personnel;<br/>
            Specialized packaging and value added services;<br/>
            Full supply chain visibility with cool chain event tracking; <br/>

            We identify ways how healthcare companies can collaborate to realize supply chain cost reductions and service synergies through Coo petition.
            <br/><br/>
            `,
        img: 'https://i.ibb.co/mC7P7X0/pharmaceutical.jpg',
    },

    {
        id: 22,
        title: 'LEATHER INDUSTRY',
        content:
            `
            The leather sector is the fourth biggest contributor to the total export earnings in Bangladesh.

            Bangladeshi leather product export earnings are forging ahead due to growing international demand for Bangladeshi leather products, mainly for quality and cheaper prices.<br/><br/>

            The brands already interested in Bangladesh as they move to diversify sourcing destination in the wake of rising production cost in the world’s biggest shoe exporter China due to a hike in labour cost there and appreciation of its currency. The lead-time of Bangladesh is also lower than China, which takes three months plus time. For Bangladesh, it is two months because Bangladesh has locally available raw materials.<br/><br/>

            Currently, Bangladesh export to various established and well-known retailers in Europe, US and Japan including Deichmann of Germany, Macys of the USA, and ABC Mart of Japan, and Timberland and Caterpillar.<br/><br/>

            The leather products that are now on export list include footwear, espadrilles, leather moccasins, dress and casual shoes, sandals, handbags, wallets, ladies bags, purse, belt, gents bag, card holder, stationary box, shopping bags, fashion bags, finished leather etc.<br/><br/>


            <b>Our services and capabilities </b><br/><br/>
            We are flexible enough to smooth the progress of freight forwarding for any industry. Each industry needs sole level of handling based on the nature of the cargo. We have the expertise in handling of Leather cargo and that has made ourselves surpass in this sector.

            <br/><br/>
            `,
        img: 'https://i.ibb.co/nBKBC4V/LEATHER-INDUSTRY.webp',
    },


    {
        id: 23,
        title: 'Food & Beverage Industry',
        content:
            `
            Food logistic professionals are responsible for making sure people around the globe get the food they need in a safe, efficient manner. Most foods require either frozen, refrigerated or some type of temperature control.  Demotrans offer a cost effective solution to complement any logistics program.<br/><br/>

            Insulated shipping solutions offered by Demotrans, such as our container liners, pallet covers, cargo blankets and bubble foil blankets provide the needed protection for a wide variety of perishable food products. Whether need dry or multi-temp transportation to and from temperature controlled value added warehouses, Demotrans  facilities offer flexibility in size, labor and market and global freight management and international trade services will have covered door-to-door.<br/><br/>

            <b>Key Benefits</b><br/>
            Global freight management;<br/>
            International trade services – when you need help navigating complex laws and regulations;<br/>
            Direct load shipments;<br/>
            Reduce freight handling claims;<br/>
            Optimized supply chain;<br/>

            We customize solutions that fit your needs.<br/>

            <br/><br/>
            `,
        img: 'https://i.ibb.co/FzMpykN/FOOD-BEVERAGE-INDUSTRY.jpg',
    },
    {
        id: 24,
        title: 'Furniture Industry',
        content:
            `
            Being one of the fastest growing sectors of Bangladesh, the furniture industry could well be the next economy booster. Due to the lower labour cost Bangladesh’s household furniture production is around 10% cheaper than the Chinese one whereas China is the largest furniture exporting country.
            <br/><br/>
            There is a huge demand for Bangladeshi soft furniture in the international markets like the USA or Europe. Also exporting to the Canada, Middle East, Japan, India and other Southeast Asian countries, like Japan, Taiwan, Singapore, Thailand and Vietnam.<br/><br/>

            The main reason behind the export growth is that Bangladesh has low-cost labour, which lowers their costs of production so Bangladesh can offer high-quality products at competitive prices.”<br/><br/>

            Moreover, there is increasing demand for hand-carved wooden furniture in the United States, Middle East, Japan and European countries. They prefer furniture designs that follow Victorian styles, hand-carved and heavy furniture, which is the dominant production style of carpenters in Bangladesh.
            </br></br>

            <b>Our Service and Capabilities </b></br>
            Logistics for the furniture industry and new furniture sector means long delivery times and high stock levels for many manufacturers and retailers.</br></br>

            Worldwide we understand complex supply chains for manufacturers, purchasing associations, retailers, and suppliers – from production and procurement markets to point of sale. In distribution, we deliver to the furniture trade for our customer and through our network of distributors, we also bring the goods directly to end customers. The assembly service is also a part our furniture logistics.

            <br/><br/>
            `,
        img: 'https://i.ibb.co/p2qBWyn/furniture-min.jpg',
    },
    {
        id: 25,
        title: 'Plastic & Rubber Industry',
        content:
            `
            The demand for Bangladesh plastic goods is increasing in different countries, especially in the USA and European countries, due mainly to restriction on Chinese products.<br/><br/>

            The plastic products that are now on export list include shopping bags, butcher bags, PVC pipe, polyethylene-sheets, ball- pens, toothbrush, toys, hanger, hand- gloves, artificial flowers, table-covers, computer accessories, wastebaskets and wall-clocks.<br/><br/>

            Major destinations of Bangladesh plastic products are Poland, China, India, Belgium, France, Germany, Canada, Spain, Australia, Japan, New Zealand, the Netherlands, Italy, United Arab Emirates (UAE), Malaysia, Hong Kong and USA.<br/><br/>

            Recently, the product has gained a wide popularity across the world for its durability, hygienic quality and reasonable price.<br/><br/>

            Also the global shortage and price-hike of rubber were forcing international traders to treat Bangladesh as an alternative source. Overseas customers are showing keen interest over Bangladeshi raw and processed rubber for its quality and lower price.<br/><br/>


            <b>Our services:</b><br/>
            Our end-to-end supply chain and transport solutions include the management of all activities from raw material sourcing, through to production, shipping, and delivery of the final product to the customer.<br/><br/>

            <b>Services Include: </b><br/>
            Airfreight (including sea-air services);<br/>
            Ocean freight (incl. FCL, LCL and bulk load);<br/>
            Landside services (incl. cargo insurance, customs clearance, inland transportation and international trade documentation);<br/>

            <br/><br/>
            `,
        img: 'https://i.ibb.co/MZfW0zF/PLASTIC-RUBBER-INDUSTRY.jpg',
    },
    {
        id: 26,
        title: 'Jute Industry',
        content:
            `
            <b>Jute Industry</b> <br/><br/>
            
            “Golden fiber of Bangladesh” jute is the second biggest export earner of Bangladesh. Since the use of natural fiber increased worldwide against the backdrop of environmental hazards, the Bangladesh’s export of jute and jute products have witnessed a significant rise during the last couple of years.<br/><br/>
            
            Bangladesh is exporting its jute products to India, China, Turkey, Iran, Syria, Uzbekistan, and America and many European and African countries.<br/><br/>
            
            Comprehensive logistics and transportation services from the core of our business – from procurement logistics right through to delivery.  Our international expertises are ready to help you.<br/><br/>


            <b>Features</b> <br/><br/>
            Bale raw jute, Jute fiber, Jute bags handled by experience stuff;<br/>
            Pick up and drop service;<br/>
            Quality control inspections;<br/>
            Check for customs clearance;<br/>
            Cross docking;<br/>
            Delivery in recurring and narrow time frames;<br/>
            Tracking & Tracing;<br/>

            <br/><br/>
            `,
        img: 'https://i.ibb.co/dmL1yw8/JUTE-INDUSTRY.jpg',
    },
    {
        id: 27,
        title: 'Retail Industry',
        content:
            `
            <b>Retail Industry: Changes and Challenges</b> <br/><br/>
            
            Globalization, shorter product lifespan and fragile economy, provide additional logistical challenges for the retail industry. Furthermore, the industry is characterized by increasingly demanding and informed customers. Speed and efficiency are critical in retail. The retail industry is challenged to right products at the right place in right time with right shape and in the right cost.<br/><br/>
            
            Improved inventory management, faster product to shelf, control and responsiveness, more efficient operations and better supply chain management and visibility are all the benefits of DemotransRetail Logistics services.  As logistics experts our service offering end-to-end supply chain solutions make getting merchandise from the manufacturer to the distributor floor easier and more convenient at less cost.<br/><br/>
            
            <b> Distribution Services: </b> <br/> Consolidation, deconsolidation, warehouse management, flow-though order fulfillment, business process outsourcing and DC bypass to help you cut costs and get your retail merchandise on shelves faster for responding to changing market conditions.<br/><br/>

            <b>Technology: </b> <br/> Advanced technologies gives more visibility through-out the supply chain to get more control over inventory and inventory shipments while in route to the distributor. Our systems are built around flexibility, and are designed to meet the unique requirements of each of our customers.<br/><br/>
            
            <b> Vendor management: </b> <br/> These proposals force our experience with major retailers and mass merchandisers for improved scorecard performance. They include purchase order and SKU-level management, document verification, order confirmation and vendor education.<br/><br/>
            
            <b>Sourcing: </b> <br/> Supervise the logistics for retailers sourcing directly in a foreign country, including lower cost locations such as inland Bangladesh, Pakistan, China and India. Offer the end-to-end logistics for direct sourcing.<br/><br/>
            
            <b> Inventory management – </b> <br/> Using the latest technology, we can provide inventory counts throughout delivery.<br/><br/>
            
            <b> Origin services:</b> <br/>  Keen follows up with vendors to ensure quick and effective turnover, from the manufacturer’s door to the port. Our services feature shipment planning, execution and a range of value-added offerings that include samples, light assembly, kitting, repackaging and conversion activities, display shippers, specialized product identification and more.<br/><br/>
            
            <b>Transportation services: </b> <br/> Offers customized transportation management solutions as well as individual flexible transport services  helps you reduce operating costs while improving access to capacity, modes, trade lanes and service times on transportation to your destinations on time – every time.<br/><br/>
            
            <b> Special Projects: </b> <br/>  Any special project of service to deliver confidence that shipments will move as planned. Offering new product launches, special orders, rework projects, unusual-size shipments and shipments requiring kitting, inserts, labeling and other value-added services.<br/><br/>
            
            <b> Freight Management: </b> <br/> Weprovide retail clients performance and decision making tools. We have also a dedicated account management team of experienced logistics professionals.<br/><br/>


            <b>  Services include:</b> <br/>
            Boxed, bagged or on hangers can process garments to your specifications, quickly and accurately, for better outcomes upon delivery.<br/>
            Quality control inspections can hold and handle minor repairs on site, avoiding disruptions to product flow.<br/>
            Pick and pack orders, and convert hanging garments into folded and bagged units ready for shipping.
            Manage compliance based on specific customer requirements.<br/>
            Domestic transportation;<br/>
            Assess documentation, including letters of credit (LC), licenses OPRs, regulatory paperwork;<br/>
            Check pre-boarding manifests and loading;<br/>
            Conduct security checks;<br/>
            Confirm post-shipment status;<br/>
            Check for customs clearance;<br/>
            Confirm the shipment’s arrival at port and its unloading schedule; Verify final delivery;<br/>

            <br/><br/>
            `,
        img: 'https://i.ibb.co/L1vsMP2/RETAIL-INDUSTRY.jpg',
    },


    // social Responsibale content start 

    {
        id: 28,
        title: 'Cancer',
        content:
            `
            Demotrans has its prominent portion of financial assistance for the research and awareness on and prevention from cancer. It donates a particular portion of the revenue regularly to the Ahsania Mission Cancer Hospital, donor no 58,<a>(http://www.ahsaniacancer.org.bd/donor_list.php )</a>. It has set a trend of arranging some public demonstrations likely seminars, workshops and symposiums on the attentiveness regarding cancer and the expected responses to the survivors.
            <br/><br/>

            Demotrans is proud to be a Bangladeshi Company actively involved in various field of Corporate Social Responsibility.For daily awareness updates please follow us: /demotrans
            <br/><br/>
            `,
        img: 'https://i.ibb.co/F8LcMTr/Cancer.jpg',
    },
    {
        id: 29,
        title: 'Tree Plantation(TP)',
        content:
            `
            We have love for nature and we understand the importance of natural preservation. A tree is never a demo. So, we engage our earnest effort to plant new trees and to raise awareness on the necessity of the flora for the sustenance of environmental clemency.
            
            <br/><br/>
            `,
        img: 'https://i.ibb.co/DY5ZP3Z/Tree-Plantation-TP.jpg',
    },
    {
        id: 30,
        title: 'Income Generating Activities (IGA)',
        content:
            `
            Demotrans lends the hand for the poor and destitute people regardless of race, caste or religion, by generating some income opportunities. Demotrans helps many NGO’s in their effort to make available healthcare to the disadvantage population of the country. It sponsors programs to build awareness on the healthcare need.

            <br/><br/>
            `,
        img: 'https://i.ibb.co/QfFZ5sR/iga.jpg',
    },
    {
        id: 31,
        title: 'Urban Beautification(UB)',
        content:
            `
            For beautifying the Dhaka city Demotranshas already started some beautification program to make it a best green city. We have already contributed with to the project named Beautification of Dhaka as a private donor.
            <br/><br/>
            `,
        img: 'https://i.ibb.co/W22zF5T/Urban-Beautification.jpg',
    },
    {
        id: 32,
        title: 'Disaster Management',
        content:
            `
            The employees of Demotrans (Dhaka/Chittagong/Khulna/UK) donated a day’s salary to help the families of the deceased workers and support the treatment and rehabilitation of the injured victims of the building collapse in Savar, Dhaka, Bangladesh.<br/><br/>

            On 24 April 2013, an eight-story garments building, Rana Plaza, collapsed in Savar. The search for the dead ended on 13 May with the death toll of 1,127 and approximately 2,500 injured people were rescued from the building alive.It is considered to be the deadliest garment-factory accident in history, as well as the deadliest accidental structural failure in modern human history.
            <br/><br/>
            `,
        img: 'https://i.ibb.co/g73TNHj/demotrans-2.jpg',
    },
    {
        id: 33,
        title: 'Scholarship and Stipends(SS)',
        content:
            `
            Demotrans has already provided some hundreds of poor meritorious students with short term stipends and some tens of extraordinary students with long term scholarships. It has planned to assist in the expansion of female, vocational and adult education as well.
            <br/><br/>
            `,
        img: 'https://i.ibb.co/pn73VQP/schollar-Ship.jpg',
    },
    {
        id: 34,
        title: 'Sponsor of Sports',
        content:
            `
            The Chairperson of Demotrans is a sports women and Demotrans is also a major sponsor of sporting events in the country.
            <br/><br/>
            `,
        img: 'https://i.ibb.co/g73TNHj/demotrans-2.jpg',
    },



]




export default mainContent;