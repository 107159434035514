import React from 'react'
import ImageCollection from '../../DataBaseFile/imageData'
import Slider from "react-slick";

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2500,

        appendDots: dots => (
            <div
                style={{
                }}
            >
                <ul style={{ margin: "-50px" }}> {dots} </ul>
            </div>
        ),
    };
    return (
        <div className='w-full md:h-[520px] 2xl:h-[800px]  mb-14'>
            <Slider {...settings} >
                {
                    ImageCollection?.map(img => <Image key={img.id} img={img.img} />)
                }
            </Slider>
        </div>
    )
}

export default Carousel

const Image = ({ img }) => {
    return (
        <div className='h-72 md:h-[520px] 2xl:h-[800px]  w-full ' >
            < img src={img} className='h-full w-full object-cover 2xl:object-center' alt='slide' />
            <div className='h-full w-full bg-black absolute z-20 top-0 opacity-5' />
        </div>
    )
}