import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./features/Homepage/HomePage";
import NavTop from "./components/NavTop/NavTop";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import DetailsPage from "./features/Detailspage/DetailsPage";
import Gallery from "./features/Gallery/Gallery";
import { Contactus } from "./features/Contact/Contactus";
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  offset: 60
})

function App() {
  return (
    <BrowserRouter>
      <NavTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/details" element={<DetailsPage />} /> */}
        <Route path="/details/:title" element={<DetailsPage />} />
        <Route path="/gallery/:photo" element={<Gallery />} />
        <Route path="/contact/:contactus" element={<Contactus />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
