import image1 from '../assets/cargo2.jpeg'
import image2 from '../assets/Image2.jpeg'
import image3 from '../assets/Image3.jpg'
import image4 from '../assets/Image4.jpg'

const ImageCollection = [
    {
        id: 3,
        img: image4
    },
    // {
    //     id: 1,
    //     img: image2
    // },
    {
        id: 2,
        img: image3
    },
    {
        id: 4,
        img: image1
    },
]

export default ImageCollection;